@import '../../styles/index';

$category-color: #686a6e;

.item {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1.25rem 0;

  @include media-from(md) {
    padding: 1.25rem 0;
  }

  *:focus-visible {
    outline: 2px solid currentColor;
  }
}

.has-padding {
  padding: 1.25rem 1rem;

  @include media-from(md) {
    padding: 1.25rem;
  }
}

.item-heading {
  margin: 0;
  font-weight: $font-weight-medium;
  font-size: $font-size-18;

  @include media-from(md) {
    font-size: $font-size-20;
    line-height: 1.4;
  }

  :hover {
    color: $surrogate-9;
  }
}

.item-heading a {
  color: inherit;
}

.item[data-favorite='true'] .item-heading {
  color: $surrogate-9;
}

.left {
  display: grid;
  grid-gap: 0.25rem;

  @include media-from(md) {
    grid-gap: 0;
  }
}

.categories {
  color: $category-color;
  font-size: $font-size-16;

  @include media-from(md) {
    line-height: 1.25;
  }
}

.category:not(:first-of-type)::before {
  content: '|';
  margin: 0 0.5rem;
}

.right {
  display: grid;
  place-items: end;

  @include media-from(md) {
    place-items: center;
  }
}
