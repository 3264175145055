@import '../../styles/index';

.button {
  all: unset;
  width: 1.5rem;
  height: 1.5rem;
  contain: strict;
  cursor: pointer;

  @include media-from(md) {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.button[aria-pressed='false'] {
  color: $surrogate-6;
}

.button[aria-pressed='true'] {
  color: $surrogate-9;
}

.icon {
  width: 100%;
  height: 100%;
}

.navItem {
  all: unset;
  height: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  @include media-from(md) {
    margin-right: 1.5rem;
    height: 1.75rem;
  }

  &[aria-pressed='false'] {
    color: $neutral-12;
  }

  &[aria-pressed='true'] {
    .icon {
      color: $surrogate-9;
    }
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.label {
  display: none;

  @include media-from(sm) {
    display: inline;
    font-size: 1rem;
    line-height: 2;
    margin-left: 0.375rem;
  }

  &.is-favorite {
    color: $surrogate-9;
  }
}
